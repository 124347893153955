import * as Sentry from '@sentry/browser'
import {SENTRY_DSN} from '../config/app'

export const initSentry = () => {
  if (process.env.ENVIRONMENT !== 'production') {
    return
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    release: `website-${process.env.HEAD_COMMIT}`,
    attachStacktrace: true,
    autoSessionTracking: false,
  })
}
