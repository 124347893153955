import {setUpNotifications} from 'reapop'

export const configNotifications = () => {
  setUpNotifications({
    defaultProps: {
      position: 'top-center',
      dismissible: true,
    },
  })
}
