import NotificationsSystem, {atalhoTheme as atayooTheme, useNotifications} from 'reapop'
import React from 'react'

export const Notifications = () => {
  const {notifications, dismissNotification} = useNotifications()
  return (
    <NotificationsSystem
      notifications={notifications}
      dismissNotification={(id) => dismissNotification(id)}
      theme={atayooTheme}
    />
  )
}
