import React from 'react'
import {NextComponentType} from 'next'

import '../styles/global.scss'
import {configNotifications} from '../starters/configNotifications'
import {NotificationsProvider} from 'reapop'
import {Notifications} from '../components/Notifications/Notifications'
import {initSentry} from '../starters/sentry'

type PageProps = Record<string, unknown>

type Props = {
  Component: NextComponentType
  pageProps: PageProps
}

initSentry()
configNotifications()

export default function MyApp({Component, pageProps}: Props) {
  return (
    <NotificationsProvider>
      <Notifications />
      <Component {...pageProps} />
    </NotificationsProvider>
  )
}
